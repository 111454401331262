import React, { useState, useEffect } from 'react';
 import { PageLayout } from './components/PageLayout';
 import { loginRequest, graphqlConfig } from './authConfig';
 import { ProfileData } from './components/ProfileData';
 import { TableData } from './components/TableData';
 import { callMsGraph } from './graph';
 import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
 import './App.css';
 import Button from 'react-bootstrap/Button';
 import Spinner from 'react-bootstrap/Spinner';

 import { PowerBIEmbed } from 'powerbi-client-react';
import * as powerbi from 'powerbi-client';

 /**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
 const ProfileContent = () => {
   const { instance, accounts } = useMsal();
   const [graphData, setGraphData] = useState(null);
   const [graphqlData, setGraphqlData] = useState(null);
   const [display, setDisplay] = useState(false);
   const userEmail = accounts[0].username;

   const [accountInfo, setAccountInfo] = useState(null);

    useEffect(() => {
        setAccountInfo(JSON.stringify(accounts[0], null, 2));
    }, [accounts]);

   function RequestGraphQL() {
        console.log(accounts[0]);
       // Silently acquires an access token which is then attached to a request for GraphQL data
       instance
           .acquireTokenSilent({
               ...loginRequest,
               account: accounts[0],
           })
           .then((response) => {
               callGraphQL(response.accessToken).then((response) => setGraphqlData(response));
           });
   }

 async function callGraphQL(accessToken) {
   setDisplay(true);
   const query = `query{
    anonMasterTables {
      items{
        DNO
        building_area
        bedroom_count
        floor_type
        local_authority
        energy_consumption_current
        energy_consumption_potential
      }
    }
  }`;
   fetch(graphqlConfig.graphqlEndpoint, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${accessToken}`,
           },
           body: JSON.stringify({ 
               query: query
           })
       })
       .then((res) => res.json())
       .then((result) => {
        console.log(result); // Log the result to the console
        setGraphqlData(result);
    });
 };

 function downloadData() {
  const filename = "graphqlData.json";
  const jsonStr = JSON.stringify(graphqlData, null, 2);
  const blob = new Blob([jsonStr], { type: "application/json" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

 return (
    <>
        <h5 className="card-title">Welcome {accounts[0].name}</h5>
        <h6 className="card-title">{accounts[0].username}</h6>
        {!graphqlData && (
            <Button variant="primary" onClick={RequestGraphQL}>
                Request Data
                {display ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : null}
            </Button>
        )}
        {graphqlData && (
          <>
            <Button variant="secondary" onClick={downloadData}>Download Data</Button>
            <TableData graphqlData={graphqlData} />
          </>
        )}
    </>
);
 };

 /**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
 const MainContent = () => {
   return (
       <div className="App">
           <AuthenticatedTemplate>
        <ProfileContent />

      </AuthenticatedTemplate>

           <UnauthenticatedTemplate>
               <h5>
                   <center>
                       Please sign-in to see your profile information.
                   </center>
               </h5>
           </UnauthenticatedTemplate>
       </div>
   );
 };


 export default function App() {
    return (
      <PageLayout>
        <center>
          <MainContent />
  
        </center>
      </PageLayout>
    );
  }